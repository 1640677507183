import { useState, useEffect, useRef } from "react";
import Loader from "../../Loader/Loader";
import EmissionClockGlobeSpinningWords from "./SpinningGlobe/EmissionClockGlobeSpinningWords";
import EmissionClockGlobeSpinningElement from "./SpinningGlobe/EmissionClockGlobeSpinningElement";
import EmissionClockGlobePuff from "./EmissionClockGlobePuff";
import globe from "../../../assets/images/section1Globe/globe.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const countrySectorPairs = 24;
const oneSecond = 1000;
const fullDegrees = 360;

const EmissionClockGlobeSpinning = ({
  activeScenario,
  selectedYear,
  isVisible,
  isWindowOnBlur,
  spinningSpeed,
  country_sector_probabilities,
  countries_flags,
}) => {
  const [initialRandomElements, setInitialRandomElements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [degrees, setDegrees] = useState(0);
  const [activeElement, setActiveElement] = useState({});
  const globeRotate = useRef(0);
  const globePosition = useRef(0);

  useEffect(() => {
    setIsLoading(true);
    setInitialRandomElements([]);
    const timeout = setTimeout(() => {
      setInitialRandomElements([...Array(countrySectorPairs).keys()]);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [activeScenario, selectedYear]);

  useEffect(() => {
    if (isLoading || isWindowOnBlur || !isVisible) {
      setDegrees(0);
      globeRotate.current = 0;
      globePosition.current = 0;
    }

    const degreeInterval = fullDegrees / countrySectorPairs;
    const timeout = setInterval(function () {
      setDegrees((prevDegrees) => (prevDegrees + degreeInterval) % 360);
      const globe = document.querySelector("#globe");
      globeRotate.current = (globeRotate.current + degreeInterval) % 360;
      if (
        globeRotate.current % (fullDegrees / countrySectorPairs) < 3 ||
        globeRotate.current % (fullDegrees / countrySectorPairs) > 12
      ) {
        globePosition.current += fullDegrees / countrySectorPairs;
        globePosition.current = globePosition.current % 7200;
        if (globe)
          globe.style.transform = `rotate(${globePosition.current}deg)`;

        const activeElement = document.querySelector('[data-active="true"]');
        activeElement &&
          setActiveElement({
            name: activeElement.getAttribute("data-name"),
            sector: activeElement.getAttribute("data-sector"),
          });
      }
    }, (spinningSpeed / countrySectorPairs) * oneSecond);

    return () => clearInterval(timeout);
  }, [spinningSpeed, isLoading, isVisible, isWindowOnBlur]);

  return isLoading || isWindowOnBlur || !isVisible ? (
    <Loader message="Loading emissions" />
  ) : (
    <>
      <EmissionClockGlobePuff
        spinningSpeed={spinningSpeed}
        countrySectorPairs={countrySectorPairs}
      />
      <div tw="relative bottom-[3.5rem] xs:bottom-[1.5rem]">
        <EmissionClockGlobeSpinningWords
          spinningSpeed={spinningSpeed}
          countrySectorPairs={countrySectorPairs}
          oneSecond={oneSecond}
          fullDegrees={fullDegrees}
          activeElement={activeElement}
        />
        <div
          tw="m-auto w-full flex justify-center max-w-[62.125rem] h-[62.125rem] bg-center bg-cover md:max-w-[35.75rem] md:h-[35.75rem] xs:h-[25.75rem] xs:max-w-[25.75rem] xxs:h-[22.75rem] xxs:max-w-[22.75rem] transition duration-500"
          id="globe"
          style={{
            backgroundImage: `url(${globe})`,
            animationDuration: `${spinningSpeed}s`,
          }}>
          <div tw="w-full h-full absolute">
            {initialRandomElements.map((_, index) => (
              <EmissionClockGlobeSpinningElement
                key={index}
                index={index}
                degrees={
                  (degrees + (index * fullDegrees) / countrySectorPairs) % 360
                }
                activeScenario={activeScenario}
                selectedYear={selectedYear}
                spinningSpeed={spinningSpeed}
                countrySectorPairs={countrySectorPairs}
                oneSecond={oneSecond}
                fullDegrees={fullDegrees}
                country_sector_probabilities={country_sector_probabilities}
                countries_flags={countries_flags}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmissionClockGlobeSpinning;

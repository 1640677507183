const useArrayOfScenarioProbs = (
  selectedYear,
  activeScenario,
  country_sector_probabilities,
  countries_flags
) => {
  const groupedCountries = country_sector_probabilities
    .filter(({ year }) => year === selectedYear)
    .reduce((arr, e) => {
      arr.push(
        Object.assign(
          {},
          e,
          countries_flags.find(({ iso3c }) => iso3c === e.iso3c)
        )
      );
      return arr;
    }, []);

  const arrayOfProbabilities = groupedCountries.map(
    (i) => i[`${activeScenario.id}_prob`]
  );
  return [groupedCountries, arrayOfProbabilities];
};

export default useArrayOfScenarioProbs;
